/* AnswerDisplay.js */
import React from 'react';

const AnswerDisplay = ({ showAnswers, currentAnswers }) => {
    return (
        <>
            {showAnswers && currentAnswers && (
                <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-4 mt-6 w-full md:w-2/3 lg:w-1/2 mx-auto">
                    <div className="p-4 bg-white shadow-md rounded-lg w-full md:w-1/2">
                        <p className="text-xl text-center">{currentAnswers[0]}</p> {/* Erste Antwort */}
                    </div>
                    <div className="p-4 bg-white shadow-md rounded-lg w-full md:w-1/2">
                        <p className="text-xl text-center">{currentAnswers[1]}</p> {/* Zweite Antwort */}
                    </div>
                </div>
            )}
        </>
    );
};

export default AnswerDisplay;
