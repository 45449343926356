/* App.js */
import React, { useState, } from 'react';
import './App.css';
import data from './answers.json';
import AnswerDisplay from './AnswerDisplay';
import StyledButton from './StyledButton';

function App() {
  const [displayText, setDisplayText] = useState(['', '']); // Zustand für die zwei Antworten, die aktuell angezeigt werden
  const [showAnswers, setShowAnswers] = useState(false); // Zustand, ob die Antworten angezeigt werden
  const [timerId, setTimerId] = useState(null); // Zustand für den Timer

  const answers = data.texts;

  const handleClick = () => {
    if (timerId) {
      clearTimeout(timerId); // Löscht den vorherigen Timer, falls er noch läuft
    }

    const randomIndex1 = Math.floor(Math.random() * answers.length); // Zufälligen Index für die erste Antwort generieren
    const randomIndex2 = Math.floor(Math.random() * answers.length); // Zufälligen Index für die zweite Antwort generieren
    const selectedAnswers = [answers[randomIndex1], answers[randomIndex2]]; // Wähle zwei Antworten basierend auf den zufälligen Indizes

    setDisplayText(selectedAnswers); // Setzt die beiden Antworten
    setShowAnswers(true); // Antworten anzeigen

    // Setze den Timer und speichere die Timer-ID
    const id = setTimeout(() => {
      setShowAnswers(false); // Antworten ausblenden
      setDisplayText(['', '']); // Antworten zurücksetzen
    }, 9000); // Antworten nach 9 Sekunden entfernen

    setTimerId(id); // Speichere die Timer-ID, um sie später löschen zu können
  };

  return (
    <div className="App text-center flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-3xl font-bold underline mt-4">
        Die Antwort, die Du suchst!
      </h1>
      <StyledButton handleClick={handleClick} />
      <AnswerDisplay showAnswers={showAnswers} currentAnswers={displayText} />
    </div>
  );
}

export default App;
